import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  TStartSessionPayload,
  TWriteStatsPayload,
} from '../../../api/view-api/types';
import { AppDispatch, useAppSelector } from '../../index';

type TMessageResponseByType = {
  [ESocketAction.START_VIDEO]: undefined;
  [ESocketAction.VIDEO_STARTED]: {
    response: {
      sessionId: string;
    };
  };
  [ESocketAction.SEND_METRICS]: {};
};

type TWSMessageData<T extends ESocketAction = ESocketAction.START_VIDEO> = {
  data: {
    message: string;
  } & TMessageResponseByType[T];
  event: T;
};

const SOCKET_URL_PATH =
  process.env.WS_HOST ?? 'wss://dev-playai-backend.aoneiro.com';

export enum ESocketAction {
  START_VIDEO = 'StartVideo',
  VIDEO_STARTED = 'onStartVideo',
  SEND_METRICS = 'SendMetrics',
}

export const initWebSocket = () => async (dispatch: AppDispatch) => {
  const socket = new WebSocket(SOCKET_URL_PATH + '/ws-indexes');

  socket.addEventListener('open', () => {
    dispatch(setSocket(socket));
  });

  socket.addEventListener('message', (message: MessageEvent<string>) => {
    const data: TWSMessageData<ESocketAction> = JSON.parse(message.data);

    switch (data.event) {
      case ESocketAction.VIDEO_STARTED:
        dispatch(
          startSession(data as TWSMessageData<ESocketAction.VIDEO_STARTED>)
        );
    }
  });

  return socket;
};

type TState = {
  socket: WebSocket | undefined;
  videoSessionStarted: boolean;
  connected: boolean;
  sessionId?: string;
};

const initialState: TState = {
  socket: undefined,
  videoSessionStarted: false,
  connected: false,
  sessionId: undefined,
};

const socketSlice = createSlice({
  name: 'socket/api',
  initialState,
  reducers: {
    setSocket(state, action: PayloadAction<WebSocket>) {
      state.socket = action.payload;
      state.connected = true;
    },
    startSession(
      state,
      action: PayloadAction<TWSMessageData<ESocketAction.VIDEO_STARTED>>
    ) {
      state.sessionId = action.payload.data.response.sessionId;
      state.videoSessionStarted = true;
    },
    startVideoAction(state, action: PayloadAction<TStartSessionPayload>) {
      state.socket?.send(
        JSON.stringify({
          event: ESocketAction.START_VIDEO,
          data: {
            ...action.payload,
            token: `Bearer ${action.payload.token}`,
          },
        })
      );
    },
    sendMetricsAction(
      state,
      action: PayloadAction<Omit<TWriteStatsPayload, 'timestamp'>>
    ) {
      state.socket?.send(
        JSON.stringify({
          event: ESocketAction.SEND_METRICS,
          data: [
            {
              ...action.payload,
              timestamp: dayjs().toISOString(),
            },
          ],
        })
      );
    },
    endVideoAction(state) {
      state.socket?.close();
    },
  },
});

export const {
  setSocket,
  startSession,
  startVideoAction,
  sendMetricsAction,
  endVideoAction,
} = socketSlice.actions;
export const socketReducer = socketSlice.reducer;
export const useSocketSelector = () => useAppSelector((state) => state.socket);
