import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { RU_LANG_SUPPORT } from '../constants';
import localeEn from './main/en.json';
import localeRu from './main/ru.json';

export enum LocaleKeys {
  MAIN = 'main',
}

let resources: Resource = {
  en: {
    [LocaleKeys.MAIN]: localeEn,
  },
};

if (RU_LANG_SUPPORT) {
  resources = {
    ...resources,
    ru: {
      [LocaleKeys.MAIN]: localeRu,
    },
  };
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: RU_LANG_SUPPORT ? 'ru' : 'en',
    debug: false,
    resources,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {});

export default i18n;
