import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import styled from '@emotion/styled';
import { Empty } from 'antd';
import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { BASE_API_PATH } from '../../api';
import { useAuthContext } from '../../context/auth-context';
import { useIndexesContext } from '../../context/indexes-context';
import { VideoContext, useVideoContext } from '../../context/video-context';
import { LocaleKeys } from '../../locale';
import { IndexesService } from '../../powered/indexes-service';
import { getSavedVideoData, saveVideoData } from '../../storage';
import { isSafari } from '../../utils/common';
import { boolToText, textToBool } from '../../utils/primitive';
import { ContinueWatchModal } from '../modals/continue-watch-modal';
import { WatchResultModal } from '../modals/watch-result-modal';
import { Analytics } from './analytics';
import { Controls } from './controls';
import { PlayButton } from './play-button';

const EmptyWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.BLACK._100};
  width: 100vw;
  height: 100vh;

  .ant-empty-description {
    color: ${({ theme }) => theme.COLORS.WHITE._600};
  }
`;

const PlayerWrapper = styled('div')`
  width: 100%;
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.COLORS.BLACK._100};
`;

const VideoComponent = styled('video')<{
  controlsVisible: 'true' | 'false';
  isFullScreen: 'true' | 'false';
}>`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: ${({ controlsVisible }) =>
    textToBool(controlsVisible) ? 'pointer' : 'none'};
`;

const LoadingWrapper = styled('div')`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 50px;
  color: white;
`;

const PlayerCore: FC = memo(() => {
  const { videoData, setIsWatchContinued } = useAuthContext();

  const {
    videoRef,
    play,
    controlsVisible,
    onWrapperMouseMove,
    togglePlay,
    isFullScreen,
    videoPending,
    isWatchResultShowing,
    setWatchResultShowing,
  } = useVideoContext();

  const { firstIndexAdded } = useIndexesContext();

  const savedData = useMemo(() => {
    // TODO: Временно, без этого импорта не та очередь построения апи стора. Нужно разобраться с этим и удалиить
    // tslint:disable-next-line:no-unused-expression
    void BASE_API_PATH;
    return getSavedVideoData();
  }, []);

  const [isContinueAvailable, setIsContinueAvailable] = useState(
    !!savedData && savedData.videoTimeEnded && savedData.videoTimeEnded > 0.5
  );

  const canContinueVideo = useMemo(() => {
    return (
      !!savedData &&
      savedData.videoTimeEnded &&
      savedData.videoTimeEnded > 0.5 &&
      savedData.videoTimeEnded < (videoData?.duration ?? 0) - 0.5
    );
  }, [savedData]);

  const handleWatchFromStart = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;

      saveVideoData({
        videoTimeEnded: 0,
      });
    }
  }, []);

  useEffect(() => {
    if (
      !!savedData &&
      savedData.videoTimeEnded &&
      savedData.videoTimeEnded > (videoData?.duration ?? 0) - 0.5 &&
      isContinueAvailable &&
      !(videoPending || !firstIndexAdded)
    ) {
      handleWatchFromStart();
    }
  }, [
    videoData,
    savedData,
    isContinueAvailable,
    firstIndexAdded,
    videoPending,
  ]);

  useEffect(() => {
    const listener = () => {
      if (videoRef.current && savedData && savedData.videoTimeEnded) {
        videoRef.current.pause();
        videoRef.current.removeEventListener('canplay', listener);
        videoRef.current.currentTime = Math.round(savedData.videoTimeEnded);
      }
    };

    if (videoRef.current && savedData) {
      videoRef.current.addEventListener('canplay', listener);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('canplay', listener);
      }
    };
  }, []);

  const handleContinueWatch = useCallback(() => {
    if (
      videoRef.current &&
      savedData &&
      savedData.videoTimeEnded &&
      savedData.videoTimeEnded > 0.5
    ) {
      videoRef.current.pause();
      videoRef.current.currentTime = Math.round(savedData.videoTimeEnded);
      setIsContinueAvailable(false);
      setIsWatchContinued(true);
    }
  }, []);

  return (
    <PlayerWrapper onMouseMove={onWrapperMouseMove}>
      {videoData && (
        <VideoComponent
          ref={videoRef}
          controls={false}
          controlsVisible={boolToText(controlsVisible)}
          isFullScreen={boolToText(isFullScreen)}
          onClick={togglePlay}
          playsInline
          autoPlay={false}
          preload="auto"
        >
          {isSafari() ? (
            <source src={videoData.originUrls} />
          ) : (
            <>
              <source src={videoData.originUrls} />
            </>
          )}
        </VideoComponent>
      )}
      {firstIndexAdded && (
        <Fragment>
          <Analytics />
          {!play && <PlayButton />}
          <Controls />
        </Fragment>
      )}
      {(!firstIndexAdded || videoPending) && (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      )}
      {isWatchResultShowing && (
        <WatchResultModal
          open={isWatchResultShowing}
          setOpen={setWatchResultShowing}
          watchAgain={handleWatchFromStart}
        />
      )}
      {isContinueAvailable &&
        canContinueVideo &&
        !isWatchResultShowing &&
        !(videoPending || !firstIndexAdded) && (
          <ContinueWatchModal
            open={isContinueAvailable}
            setOpen={setIsContinueAvailable}
            continueWatch={handleContinueWatch}
            watchFromStart={handleWatchFromStart}
          />
        )}
    </PlayerWrapper>
  );
});

export const Player: FC = () => {
  const { videoData, appError, isEmptyId } = useAuthContext();
  const { t } = useTranslation([LocaleKeys.MAIN]);

  if (appError || isEmptyId) {
    return (
      <EmptyWrapper>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('video.notFound')}
        />
      </EmptyWrapper>
    );
  }

  if (!videoData) {
    return (
      <PlayerWrapper>
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      </PlayerWrapper>
    );
  }

  return (
    <VideoContext>
      <IndexesService>
        <PlayerCore />
      </IndexesService>
    </VideoContext>
  );
};
