import PauseOutlined from '@ant-design/icons/lib/icons/PauseOutlined';
import styled from '@emotion/styled';

import { MediaConstants } from '../../styles';
import { textToBool } from '../../utils/primitive';
import { VideoPlayFilledIcon } from '../icons';

export const ControlsWrapper = styled('div')<{ visible: 'true' | 'false' }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ visible }) =>
    textToBool(visible) ? 'unset' : 'translateY(10px)'};
  opacity: ${({ visible }) => (textToBool(visible) ? 1 : 0)};
  pointer-events: none;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;

  ${MediaConstants.MOBILE} {
    bottom: 16px;
  }

  .vd_cntrls_inner {
    display: flex;
    justify-content: space-between;
    min-width: 560px;
    align-items: center;
    padding: 10px;
    max-width: 666px;
    width: 100%;
    margin: 0 20px;
    background-color: rgba(0, 0, 0, 0.4);
    height: 60px;
    border-radius: 30px;
    pointer-events: ${({ visible }) => (textToBool(visible) ? 'all' : 'none')};

    ${MediaConstants.TABLET} {
      width: 100%;
      min-width: unset;
    }

    &-side {
      display: flex;
      align-items: center;
    }
  }
`;

export const PlayButton = styled('div')`
  width: 70px;
  height: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._100};
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  font-size: 20px;
  transition: background-color 0.3s ease-out;

  ${MediaConstants.MOBILE} {
    height: 30px;
    width: 50px;
    font-size: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.ACCENT._400};
  }

  .icon {
    transform: translateX(3px);
  }
`;

export const ControlButtonsWrapper = styled('div')`
  display: flex;
  gap: 20px;
  height: 40px;
`;

export const BackToProfileButton = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const VideoPlayIcon = styled(VideoPlayFilledIcon)`
  filter: brightness(0) saturate(100%) invert(22%) sepia(3%) saturate(7493%)
    hue-rotate(131deg) brightness(78%) contrast(83%);
`;

export const VideoPauseIcon = styled(PauseOutlined)`
  filter: brightness(0) saturate(100%) invert(22%) sepia(3%) saturate(7493%)
    hue-rotate(131deg) brightness(78%) contrast(83%);
`;

export const VolumeWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 22px;
    height: 20px;
  }

  svg:hover,
  svg:hover {
    filter: brightness(0) saturate(100%) invert(72%) sepia(27%) saturate(958%)
      hue-rotate(125deg) brightness(95%) contrast(90%);
  }

  .slider_block {
    position: absolute;
    top: -133px;
    left: -6px;
    transition:
      display 0.8s,
      opacity 0.5s linear;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 8%;
  }

  .slider {
    transition:
      visibility 0.8s,
      opacity 0.5s linear;
    width: 4px;
    margin: 14px;
    padding: 0;
    height: 85px;
  }

  .ant-slider-track {
    background-color: #39d5c9;
  }

  .ant-slider-step {
    inset-inline-start: 0;
  }

  .ant-slider-handle {
    inset-inline-start: -3px;
  }

  .ant-slider-handle::after {
    background-color: #39d5c9;
    box-shadow: 0 0 0 2px #39d5c9;
  }
`;
