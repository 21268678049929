import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from './icon-types';

const BackToProfileIcon: FC<TIconBaseProps> = ({}) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H12C12.5523 0 13 0.447715 13 1C13 1.55228 12.5523 2 12 2H3C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H12C12.5523 18 13 18.4477 13 19C13 19.5523 12.5523 20 12 20H3C1.34315 20 0 18.6569 0 17V3ZM12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L10.4142 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H10.4142L12.7071 13.2929C13.0976 13.6834 13.0976 14.3166 12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L7.29289 10.7071C6.90237 10.3166 6.90237 9.68342 7.29289 9.29289L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};

export default withTheme(BackToProfileIcon);
