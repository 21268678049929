import { Button, Form, Input, Space } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import { useCallback, useId } from 'react';
import { Link } from 'react-router-dom';

import { useLoginMutation } from '../../api/view-api';
import { TLoginPayload } from '../../api/view-api/types';
import { DarkTheme } from '../../styles';
import { EMAIL_REGEX } from '../../utils/regex';
import NotificationConfigProvider from './notification-config-provider';
import { useOpenRegisterNotification } from './register-notification';
import { TBaseNotificationProps } from './types';

type TProps = TBaseNotificationProps & {
  messageApi: MessageInstance;
};

export const useOpenLoginNotification = ({
  t,
  notificationApi,
  messageApi,
  parentNoticeKey,
}: TProps): ((parentOpen: () => void) => void) => {
  const [form] = Form.useForm();

  const [login, { isLoading }] = useLoginMutation();

  const notificationKey = useId();

  const handleFormFinish = useCallback(
    async (values: TLoginPayload) => {
      try {
        await login({
          ...values,
        }).unwrap();

        messageApi.success(t('auth.success'));
        notificationApi.destroy();
      } catch (e) {
        messageApi.error(t('auth.error'));
      }
    },
    [t]
  );

  const handleBack = useCallback(() => {
    notificationApi.destroy(notificationKey);
  }, []);

  const handleOpenRegisterForm = useOpenRegisterNotification({
    t,
    messageApi,
    notificationApi,
    parentNoticeKey: notificationKey,
  });

  return useCallback(
    (parentOpen: () => void) => {
      notificationApi.destroy(parentNoticeKey);

      const openNotice = () => {
        notificationApi.open({
          message: t('auth.loginTitle'),
          key: notificationKey,
          description: (
            <NotificationConfigProvider>
              <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                style={{
                  marginTop: 20,
                  width: '100%',
                }}
                onFinish={handleFormFinish}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('rules.required'),
                    },
                    {
                      pattern: EMAIL_REGEX,
                      message: t('rules.emailInvalid'),
                    },
                  ]}
                >
                  <Input
                    placeholder="email@mail.net"
                    size="large"
                    type="email"
                  />
                </Form.Item>
                <Form.Item
                  label={t('form.password')}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('rules.required'),
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete="current-password"
                    placeholder={t('form.password')}
                    size="large"
                  />
                </Form.Item>
              </Form>
              <Space>
                <Button
                  type="primary"
                  onClick={form.submit}
                  size="large"
                  loading={isLoading}
                >
                  {t('button.enter')}
                </Button>
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    handleBack();
                    setTimeout(() => {
                      parentOpen();
                    }, 100);
                  }}
                  size="large"
                  style={{
                    color: DarkTheme.COLORS.WHITE._100,
                  }}
                >
                  {t('button.back')}
                </Button>
              </Space>
              <Space>
                <Link
                  to={
                    !!process.env.PASSWORD_RESET_LINK
                      ? process.env.PASSWORD_RESET_LINK
                      : 'https://player-cabinet.aoneiro.com/password'
                  }
                  target="_blank"
                >
                  <Button
                    type="link"
                    style={{
                      padding: 0,
                      marginTop: 20,
                      color: DarkTheme.COLORS.ACCENT._100,
                      textDecoration: 'underline',
                    }}
                  >
                    {t('button.losePassword')}
                  </Button>
                </Link>
                <Button
                  type="link"
                  style={{
                    padding: 0,
                    marginTop: 20,
                    color: DarkTheme.COLORS.ACCENT._100,
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    handleOpenRegisterForm(openNotice);
                  }}
                >
                  {t('button.register')}
                </Button>
              </Space>
            </NotificationConfigProvider>
          ),
          placement: 'topRight',
          duration: 0,
          closeIcon: false,
        });
      };

      setTimeout(() => {
        openNotice();
      }, 100);
    },
    [notificationApi, parentNoticeKey]
  );
};
