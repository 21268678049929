import { createSlice } from '@reduxjs/toolkit';

import { viewApi } from '../../../api';
import { TParsedJWT, parseJwt } from '../../../utils/jwt';
import { RootState } from '../../index';

export type TState = {
  authorizationToken: string | null;
  authed: boolean;
  decodedToken: TParsedJWT | null;
  userId: string | undefined;
  needToSelectAuthType: boolean;
  currentGuestToken: string | null;
  isGuest: boolean;
};

const initialState: TState = {
  authorizationToken: null,
  authed: false,
  decodedToken: null,
  needToSelectAuthType: false,
  currentGuestToken: null,
  isGuest: false,
  userId: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    ...initialState,
  },
  reducers: {
    stayAsGuestAction(state) {
      if (state.currentGuestToken) {
        const decodedData = parseJwt(state.currentGuestToken);

        state.authorizationToken = state.currentGuestToken;
        state.decodedToken = decodedData;
        state.userId = decodedData.account.id;

        state.needToSelectAuthType = false;
        state.currentGuestToken = null;
        state.isGuest = true;
        state.authed = true;
      }
    },
    logoutAction() {
      localStorage.clear();
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      viewApi.endpoints.getLinkData.matchFulfilled,
      (state, { payload }) => {
        if (payload.guestAccessToken) {
          state.needToSelectAuthType = true;
          state.currentGuestToken = payload.guestAccessToken;

          state.authorizationToken = null;
          state.authed = false;
          state.decodedToken = null;
          state.isGuest = false;
          state.userId = undefined;
        } else {
          state.needToSelectAuthType = false;
          state.currentGuestToken = null;
        }
      }
    );
    builder.addMatcher(
      viewApi.endpoints.guestLogin.matchFulfilled,
      (state, { payload }) => {
        if (payload.authorizationToken) {
          const decodedData = parseJwt(payload.authorizationToken);

          state.needToSelectAuthType = false;
          state.authorizationToken = payload.authorizationToken;
          state.currentGuestToken = null;
          state.decodedToken = decodedData;
          state.userId = decodedData.account.id;
          state.isGuest = true;
          state.authed = true;
        }
      }
    );
    builder.addMatcher(
      viewApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.authorizationToken) {
          const decodedData = parseJwt(payload.authorizationToken);

          state.needToSelectAuthType = false;
          state.authorizationToken = payload.authorizationToken;
          state.currentGuestToken = null;
          state.decodedToken = decodedData;
          state.userId = decodedData.account.id;
          state.isGuest = false;
          state.authed = true;
        }
      }
    );
    builder.addMatcher(
      viewApi.endpoints.registration.matchFulfilled,
      (state, { payload }) => {
        if (payload.authorizationToken) {
          const decodedData = parseJwt(payload.authorizationToken);

          state.needToSelectAuthType = false;
          state.authorizationToken = payload.authorizationToken;
          state.currentGuestToken = null;
          state.decodedToken = decodedData;
          state.userId = decodedData.account.id;
          state.isGuest = false;
          state.authed = true;
        }
      }
    );
  },
});

export const { stayAsGuestAction, logoutAction } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const authSelector = (state: RootState) => state.auth.auth;
