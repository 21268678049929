import { Button, Form, Input, Space } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import { useCallback, useId } from 'react';

import { useRegistrationMutation } from '../../api/view-api';
import { DarkTheme } from '../../styles';
import { EMAIL_REGEX } from '../../utils/regex';
import NotificationConfigProvider from './notification-config-provider';
import { TBaseNotificationProps } from './types';

type TProps = TBaseNotificationProps & {
  messageApi: MessageInstance;
};

type TRegisterForm = {
  email: string;
  password: string;
  passwordRepeat: string;
};

export const useOpenRegisterNotification = ({
  t,
  notificationApi,
  messageApi,
  parentNoticeKey,
}: TProps): ((parentOpen: () => void) => void) => {
  const [form] = Form.useForm<TRegisterForm>();

  const [register, { isLoading }] = useRegistrationMutation();

  const notificationKey = useId();

  const handleFormFinish = useCallback(
    async (values: TRegisterForm) => {
      try {
        await register({
          ...values,
        }).unwrap();

        messageApi.success(t('registration.success'));
        notificationApi.destroy();
      } catch (e) {
        messageApi.error(t('registration.error'));
      }
    },
    [t]
  );

  const handleBack = useCallback(() => {
    notificationApi.destroy(notificationKey);
  }, []);

  return useCallback(
    (parentOpen: () => void) => {
      notificationApi.destroy(parentNoticeKey);
      setTimeout(() => {
        notificationApi.open({
          message: t('auth.registrationTitle'),
          key: notificationKey,
          description: (
            <NotificationConfigProvider>
              <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                style={{
                  marginTop: 20,
                  width: '100%',
                }}
                onFinish={handleFormFinish}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('rules.required'),
                    },
                    {
                      pattern: EMAIL_REGEX,
                      message: t('rules.emailInvalid'),
                    },
                  ]}
                >
                  <Input
                    placeholder="email@mail.net"
                    size="large"
                    type="email"
                  />
                </Form.Item>
                <Form.Item
                  label={t('form.password')}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('rules.required'),
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete="current-password"
                    placeholder={t('form.password')}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label={t('form.passwordRepeat')}
                  name="passwordRepeat"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: t('rules.required'),
                    },
                    {
                      validator: (_, value) => {
                        const passwordValue = form.getFieldValue('password');

                        if (!passwordValue || !value) {
                          return Promise.resolve();
                        }

                        if (passwordValue === value) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(t('rules.passwordMismatch'))
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete="current-password"
                    placeholder={t('form.password')}
                    size="large"
                  />
                </Form.Item>
              </Form>
              <Space>
                <Button
                  type="primary"
                  onClick={form.submit}
                  size="large"
                  loading={isLoading}
                >
                  {t('button.enter')}
                </Button>
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    handleBack();
                    setTimeout(() => {
                      parentOpen();
                    }, 100);
                  }}
                  size="large"
                  style={{
                    color: DarkTheme.COLORS.WHITE._100,
                  }}
                >
                  {t('button.back')}
                </Button>
              </Space>
            </NotificationConfigProvider>
          ),
          placement: 'topRight',
          duration: 0,
          closeIcon: false,
        });
      }, 100);
    },
    [notificationApi, parentNoticeKey, t]
  );
};
