import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../context/auth-context';
import { useIndexesContext } from '../../context/indexes-context';
import { LocaleKeys } from '../../locale';
import { MediaConstants } from '../../styles';
import { AnalyticsItem } from './analytics-item';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;

  ${MediaConstants.MOBILE} {
    top: 12px;
  }
`;

const VideoTitle = styled('h3')`
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 14px 0;
  max-width: calc(100vw - 40px);
`;

const Inner = styled('div')`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 666px;
  margin: 0 20px;
  pointer-events: all;

  ${MediaConstants.MOBILE} {
    margin: 0 12px;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      background: transparent;
      width: 0;
      max-width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      width: 0;
      height: 0;
    }
  }
`;

export const Analytics: FC = () => {
  const { indexData, analytics } = useIndexesContext();
  const { videoData } = useAuthContext();
  const { t } = useTranslation([LocaleKeys.MAIN]);

  return (
    <Wrapper>
      {videoData?.title && <VideoTitle>{videoData.title}</VideoTitle>}
      {analytics && (
        <Inner>
          <AnalyticsItem
            value={indexData.attention || 0}
            label={t('analytics.attention')}
          />
          <AnalyticsItem
            value={indexData.involvement || 0}
            label={t('analytics.involvement')}
          />
          <AnalyticsItem
            value={indexData.happiness || 0}
            label={t('analytics.happiness')}
          />
          <AnalyticsItem
            value={indexData.amazement || 0}
            label={t('analytics.amazement')}
          />
          <AnalyticsItem
            value={indexData.tiredness || 0}
            label={t('analytics.tiredness')}
          />
        </Inner>
      )}
    </Wrapper>
  );
};
