export function timeToHMS(value: number = 0): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  const myValue = isNaN(value) ? 0 : value;

  let hours: string | number = Math.floor(myValue / 3600);
  let minutes: string | number = Math.floor((myValue - hours * 3600) / 60);
  let seconds: string | number = myValue - hours * 3600 - minutes * 60;

  hours = hours === Infinity ? 0 : hours;
  minutes = minutes === Infinity ? 0 : minutes;
  seconds = seconds === Infinity ? 0 : seconds;

  return {
    hours,
    minutes,
    seconds,
  };
}

export function timeToString(value: number = 0): string {
  const hms = timeToHMS(value);

  let hours: string | number = hms.hours;
  let minutes: string | number = hms.minutes;
  let seconds: string | number = hms.seconds;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return (
    `${Number(hours) > 0 ? hours + ':' : ''}` +
    (minutes || '00') +
    ':' +
    (seconds || '00')
  );
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
