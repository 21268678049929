import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from './icon-types';

const VideoPlayIcon: FC<TIconBaseProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="90"
      height="106"
      viewBox="0 0 90 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.5"
        d="M83.0625 44.4922C89.1172 48.2031 89.1172 56.9922 83.0625 60.7031L14.3125 101.328C8.0625 105.039 0.25 100.547 0.25 93.125V11.875C0.25 3.86719 8.64844 0.546875 14.3125 3.86719L83.0625 44.4922Z"
        fill={theme.COLORS.WHITE._400}
      />
    </svg>
  );
};

export default withTheme(VideoPlayIcon);
