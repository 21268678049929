import {
  FC,
  Fragment,
  PropsWithChildren,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useAuthContext } from '../context/auth-context';
import { useIndexesContext } from '../context/indexes-context';
import { useVideoContext } from '../context/video-context';
import { IExportIndex } from '../types/index-core-types';
import { UserVideo } from './user-video';

const virtualCanvas = document.createElement('canvas');

const tupleOfNumbers: number[] = [0];

// Функция для добавления нового значения в кортеж
function addNewValue(value: number): void {
  tupleOfNumbers.push(value); // Добавляем новое значение в конец кортежа
  if (tupleOfNumbers.length > 5) {
    tupleOfNumbers.shift(); // Удаляем первый элемент, если длина кортежа больше пяти
  }
}

export const IndexesService: FC<PropsWithChildren> = memo(({ children }) => {
  const { videoRef, isCameraError } = useVideoContext();

  const [indexesStarted, setIndexesStarted] = useState(false);

  const {
    personVideoRef,
    personCameraStarted,
    setStoppedByIndexes,
    stoppedByIndexes,
    setFirstIndexAdded,
    firstIndexAdded,
    viewGuarantee,
    videoWasStarted,
    setIndexData,
    indexData,
  } = useIndexesContext();

  const { linkData } = useAuthContext();

  useEffect(() => {
    if (
      personVideoRef.current &&
      personVideoRef.current.video &&
      personCameraStarted &&
      !isCameraError &&
      !indexesStarted
    ) {
      const video = personVideoRef.current.video;

      setIndexesStarted(true);

      window.createFaceDetector(
        video,
        {
          onIndex: () => {},
          onSecondIndex: (data: any) => {
            if (data as IExportIndex) {
              addNewValue(data.attention);

              const attention = data.attention;
              const involvement = Math.min(
                Math.round(0.6 * attention + 0.4 * (100 - data.tiredness)),
                100
              );

              setIndexData({
                ...data,
                attention,
                involvement,
                amazement: 100 - attention,
                tiredness: data.tiredness,
              } as IExportIndex);
            }
          },
          allowRecalibration: () => {
            return true;
          },
        },
        virtualCanvas
      );
    }
  }, [personCameraStarted, isCameraError, indexesStarted]);

  const increaseAttentionValue = useMemo(
    () => linkData?.videoSetting.increaseAttentionValue ?? 50,
    [linkData]
  );

  useEffect(() => {
    if (videoRef.current) {
      if (
        (indexData.attention || 0) < increaseAttentionValue &&
        viewGuarantee &&
        !videoRef.current.paused
      ) {
        videoRef.current.pause();
        setStoppedByIndexes(true);
      } else if (
        stoppedByIndexes &&
        videoRef.current.paused &&
        videoWasStarted &&
        (indexData.attention || 0) >= increaseAttentionValue
      ) {
        setStoppedByIndexes(false);
        void videoRef.current.play();
      }

      if (
        indexData.attention !== undefined &&
        indexData.attention !== 0 &&
        !firstIndexAdded
      ) {
        setFirstIndexAdded(true);
      }
    }
  }, [
    indexData,
    firstIndexAdded,
    viewGuarantee,
    stoppedByIndexes,
    videoWasStarted,
    increaseAttentionValue,
  ]);

  return (
    <Fragment>
      <UserVideo ref={personVideoRef} />
      {children}
    </Fragment>
  );
});
