import { Middleware, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';

import { baseEmptyApi } from '../../api';
import viewApi from '../../api/view-api';
import { isServerError } from '../../utils/server';
import { logoutAction } from '../features/auth/auth.slice';
import { AppDispatch, RootState } from '../index';

function isPayloadApiAction(
  action: unknown | PayloadAction
): action is PayloadAction {
  return (
    (action as PayloadAction).type !== undefined &&
    (action as PayloadAction).type.startsWith(baseEmptyApi.reducerPath)
  );
}

const authMiddleware: Middleware =
  (api: MiddlewareAPI<AppDispatch, RootState>) => (next) => (action) => {
    if (isPayloadApiAction(action)) {
      if (viewApi.endpoints.getLinkData.matchRejected(action)) {
        if (isServerError(action.payload)) {
          if (action.payload.status === 403) {
            api.dispatch(logoutAction());
          }
        }
      }
    }

    return next(action);
  };

export default authMiddleware;
