import { createSlice } from '@reduxjs/toolkit';

import { viewApi } from '../../../api';
import { TLinkResponse } from '../../../api/view-api/types';

export type TState = {
  linkData: TLinkResponse['link'] | undefined;
};

const initialState: TState = {
  linkData: undefined,
};

const linkSlice = createSlice({
  name: 'video/link',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      viewApi.endpoints.getLinkData.matchFulfilled,
      (state, { payload }) => {
        state.linkData = payload.link;
      }
    );
  },
});

export const linkReducer = linkSlice.reducer;
