import { saveVideoData } from '../../storage';
import { baseEmptyApi } from '../api';
import { TCreatedVideo } from '../response-types';
import {
  TCreateGustToken,
  TCreateStatsPayload,
  TLinkResponse,
  TLoginPayload,
  TLoginResponse,
  TPatchFioPayload,
  TPatchStatsPayload,
  TRegistrationPayload,
  TStatsResponse,
  TUserByLinkStatsPayload,
  TUserByLinkStatsResponse,
  TUserLinkStatResponse,
} from './types';

const viewApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLinkData: builder.query<TLinkResponse, string>({
      query: (credentials) => ({
        url: `link/${credentials}`,
        method: 'GET',
      }),
    }),
    login: builder.mutation<TLoginResponse, TLoginPayload>({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
    }),
    registration: builder.mutation<TLoginResponse, TRegistrationPayload>({
      query: (body) => ({
        url: 'auth/registration',
        method: 'POST',
        body,
      }),
    }),
    createSessionStats: builder.mutation<TStatsResponse, TCreateStatsPayload>({
      query: (body) => ({
        url: 'stats',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, api) {
        try {
          const response = await api.queryFulfilled;

          saveVideoData({
            statsData: response.data,
          });
        } catch (e) {
          return Promise.reject(e);
        }
      },
    }),
    patchSessionStats: builder.mutation<TStatsResponse, TPatchStatsPayload>({
      query: (body) => ({
        url: 'stats',
        method: 'PATCH',
        body,
      }),
    }),
    guestLogin: builder.mutation<TCreateGustToken, string>({
      query: (nickname) => ({
        url: 'auth/guest-login',
        method: 'POST',
        body: {
          nickname,
        },
      }),
    }),
    getVideoData: builder.query<TCreatedVideo, string>({
      query: (id) => ({
        url: `video/${id}`,
        method: 'GET',
      }),
    }),
    patchMyUser: builder.mutation<
      void,
      {
        data: TPatchFioPayload;
        token: string;
      }
    >({
      query: (data) => ({
        url: 'me',
        method: 'PATCH',
        body: data.data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    getSessionStats: builder.query<
      TUserLinkStatResponse,
      {
        sessionId: string;
      }
    >({
      query: ({ ...params }) => ({
        url: 'stats/session-stats',
        method: 'GET',
        params,
      }),
    }),
    getUserStatsByLink: builder.query<
      TUserByLinkStatsResponse,
      TUserByLinkStatsPayload
    >({
      query: (data) => ({
        url: 'stats/user-stats-by-link',
        method: 'GET',
        params: data,
      }),
    }),
    increaseWatchTillEndCount: builder.mutation<
      void,
      {
        linkId: string;
        userId: string;
        videoId: string;
        sessionId: string;
      }
    >({
      query: (data) => ({
        url: 'stats',
        method: 'POST',
        body: {
          watchedTillEndCount: 1,
          ...data,
        },
      }),
    }),
  }),
});

export const {
  useGetLinkDataQuery,
  useLoginMutation,
  useCreateSessionStatsMutation,
  useGetVideoDataQuery,
  usePatchSessionStatsMutation,
  useRegistrationMutation,
  usePatchMyUserMutation,
  useGuestLoginMutation,
  useGetSessionStatsQuery,
  useGetUserStatsByLinkQuery,
  useIncreaseWatchTillEndCountMutation,
} = viewApi;

export default viewApi;
