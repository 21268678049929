import { ConfigProvider, message, notification, theme } from 'antd';
import dayjs from 'dayjs';
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TCreatedVideo, TLinkResponse } from '../api/response-types';
import {
  useCreateSessionStatsMutation,
  useGetLinkDataQuery,
  useGetVideoDataQuery,
} from '../api/view-api';
import { TStatsResponse } from '../api/view-api/types';
import { useOpenMainAuthNotification } from '../components/notifications/main-auth-notification';
import { LocaleKeys } from '../locale';
import { useAppDispatch } from '../state';
import { useAuthSelector } from '../state/features/auth/selectors';
import { startVideoAction, useSocketSelector } from '../state/features/socket';
import { getSavedVideoData } from '../storage';
import { DarkTheme } from '../styles';

type TContext = {
  authReady: boolean;
  sessionId?: string;
  statsData?: TStatsResponse;
  videoData?: TCreatedVideo;
  linkData?: TLinkResponse['link'];
  appError: boolean;
  isEmptyId: boolean;
  handleCreateSessionStats: () => void;
  setIsWatchContinued: Dispatch<SetStateAction<boolean>>;
};

const context = createContext<TContext>({
  authReady: false,
  sessionId: undefined,
  statsData: undefined,
  videoData: undefined,
  linkData: undefined,
  appError: false,
  isEmptyId: false,
  handleCreateSessionStats: () => {},
  setIsWatchContinued: () => {},
});

export const useAuthContext = () => useContext(context);

export const AuthContext: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { needToSelectAuthType, authed, authorizationToken, decodedToken } =
    useAuthSelector();
  const [t] = useTranslation([LocaleKeys.MAIN]);
  const [notificationApi, noticeContextHolder] = notification.useNotification();
  const [messageApi, messageContextHolder] = message.useMessage();
  const { connected, sessionId, videoSessionStarted } = useSocketSelector();

  const [playerReadyToWatch, setPlayerReady] = useState(false);
  const [isEmptyId, setIsEmptyId] = useState(false);

  const [isWatchContinued, setIsWatchContinued] = useState(false);

  const savedData = useMemo(() => {
    return getSavedVideoData();
  }, []);

  const [createSessionStats, { data: statsSessionData }] =
    useCreateSessionStatsMutation();

  const isAuthNotificationShowed = useRef<boolean>(false);

  const linkId = useMemo(() => {
    const id = location.pathname.slice(1, location.pathname.length) as
      | string
      | undefined;

    if (!id) {
      setIsEmptyId(true);
    } else {
      setIsEmptyId(false);
    }

    return id;
  }, [location]);

  const { data: linkData, isError: isLinkError } = useGetLinkDataQuery(
    linkId as string,
    {
      skip: !linkId,
    }
  );

  const { data: videoData, isError: isVideoError } = useGetVideoDataQuery(
    linkData?.link.videoId as string,
    {
      skip: !linkData?.link.videoId || !authed,
    }
  );

  useEffect(() => {
    if (isLinkError || isVideoError) {
      void messageApi.error(t('video.errorOnLoad'));
    }
  }, [isVideoError, isLinkError]);

  useEffect(() => {
    if (authed && authorizationToken && linkId && connected) {
      dispatch(
        startVideoAction({
          token: authorizationToken,
          linkId,
        })
      );
    }
  }, [authed, authorizationToken, linkId, connected]);

  const handleOpenAuthNotice = useOpenMainAuthNotification({
    t,
    notificationApi,
    messageApi,
  });

  useEffect(() => {
    if (needToSelectAuthType && !isAuthNotificationShowed.current) {
      isAuthNotificationShowed.current = true;

      handleOpenAuthNotice();
    }
  }, [needToSelectAuthType, handleOpenAuthNotice]);

  const handleCreateSessionStats = useCallback(() => {
    console.log('isWatchContinued', isWatchContinued);
    if (linkData && decodedToken && sessionId && !isWatchContinued) {
      createSessionStats({
        linkId: linkData.link.id,
        userId: decodedToken.account.id,
        videoId: linkData.link.videoId,
        sessionId,
        data: '{}',
        viewsCount: 1,
        start: dayjs().toISOString(),
        end: dayjs().toISOString(),
        viewingDuration: 0,
        pauseClickCount: 0,
      });
    }
  }, [linkData, decodedToken, sessionId, isWatchContinued]);

  useEffect(() => {
    if (
      connected &&
      sessionId &&
      videoSessionStarted &&
      linkData &&
      decodedToken
    ) {
      setPlayerReady(true);
    }
  }, [connected, sessionId, videoSessionStarted, linkData, decodedToken]);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        components: {
          Notification: {
            colorBgElevated: DarkTheme.COLORS.BLACK._400,
            borderRadiusLG: 20,
            borderRadiusSM: 16,
            borderRadiusXS: 10,
            colorTextHeading: DarkTheme.COLORS.WHITE._100,
          },
        },
      }}
    >
      <context.Provider
        value={{
          statsData:
            isWatchContinued && savedData?.statsData
              ? savedData.statsData
              : statsSessionData,
          authReady: playerReadyToWatch,
          sessionId,
          videoData,
          linkData: linkData?.link,
          appError: isLinkError || isVideoError,
          isEmptyId,
          handleCreateSessionStats,
          setIsWatchContinued,
        }}
      >
        {messageContextHolder}
        {noticeContextHolder}
        {children}
      </context.Provider>
    </ConfigProvider>
  );
};
