export const MediaConstants = {
  MOBILE: '@media only screen and (max-width: 768px)',
  TABLET: '@media (max-width: 640px)',
  TABLET_SM: '@media (max-width: 584px)',
} as const;

export type TTheme = {
  COLORS: {
    ACCENT: {
      _100: string;
      _200: string;
      _300: string;
      _400: string;
    };
    WHITE: {
      _100: string;
      _200: string;
      _300: string;
      _400: string;
      _500: string;
      _600: string;
    };
    BLACK: {
      _100: string;
      _100_00: string;
      _200: string;
      _300: string;
      _400: string;
      _500: string;
      _600: string;
      _700: string;
      _800: string;
      _900: string;
    };
    DANGER: string;
  };
};

export const DarkTheme: TTheme = {
  COLORS: {
    ACCENT: {
      _100: '#39D5C9',
      _200: '#009F93',
      _300: '#007A71',
      _400: '#54827E',
    },
    WHITE: {
      _100: '#FFFFFF',
      _200: '#E6E6E6',
      _300: '#CBCBCB',
      _400: '#B2B2B2',
      _500: '#B0B0B0',
      _600: '#ACACAC',
    },
    BLACK: {
      _100: '#141414',
      _200: '#151515',
      _300: '#1C1C1C',
      _400: '#242424',
      _500: '#3D3D3D',
      _600: '#545454',
      _700: '#767676',
      _800: '#898989',
      _900: '#8C8C8C',
      _100_00: '#101010',
    },
    DANGER: '#D53939',
  },
};
