import { TVideoChunkData } from '../api/response-types';
import { TStatsResponse } from '../api/view-api/types';
import { TStatStatus } from '../context/stat-context';

export const ACCESS_TOKEN_LS_PATH = 'plai-access';

export type TVideoData = {
  videoId: string;
  chunks: TVideoChunkData;
  videoTimeEnded: number;
  statStatus: TStatStatus;
  statsData?: TStatsResponse;
};

export type TState = {
  videoData: Record<string, Partial<TVideoData>>;
};

const initialState: TState = {
  videoData: {},
};

const VIDEO_LS_STORAGE_KEY = 'id5_play_video_data';

export function initState(): TState | undefined {
  const isHaveData = !!localStorage.getItem(VIDEO_LS_STORAGE_KEY);

  if (!isHaveData) {
    localStorage.setItem(VIDEO_LS_STORAGE_KEY, JSON.stringify(initialState));
    return { ...initialState };
  }
}

export function getSavedVideoData(): Partial<TVideoData> | undefined {
  const linkId = window.location.pathname.slice(
    1,
    window.location.pathname.length
  );

  const storage = localStorage.getItem(VIDEO_LS_STORAGE_KEY);

  if (storage) {
    if (linkId) {
      const state: TState = JSON.parse(storage);

      return state.videoData[linkId];
    }
  } else {
    initState();
    return getSavedVideoData();
  }
}

export function saveVideoData(data: Partial<TVideoData>) {
  const linkId = window.location.pathname.slice(
    1,
    window.location.pathname.length
  );

  const storage = localStorage.getItem(VIDEO_LS_STORAGE_KEY);

  if (storage) {
    if (linkId) {
      let state: TState = JSON.parse(storage);

      state = {
        ...state,
        videoData: {
          ...state.videoData,
          [linkId]: {
            ...(state.videoData[linkId] ?? {}),
            ...data,
          },
        },
      } as TState;

      localStorage.setItem(VIDEO_LS_STORAGE_KEY, JSON.stringify(state));
    }
  } else {
    initState();
    saveVideoData(data);
  }
}
