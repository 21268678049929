import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from './icon-types';

const FullScreenIcon: FC<TIconBaseProps> = ({}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2941 1C12.2941 0.447715 12.7418 0 13.2941 0L19 2.38419e-07C19.5523 2.98023e-07 20 0.447716 20 1V6.70588C20 7.25817 19.5523 7.70588 19 7.70588C18.4477 7.70588 18 7.25817 18 6.70588V2L13.2941 2C12.7418 2 12.2941 1.55228 12.2941 1ZM0 1C0 0.447716 0.447715 2.38419e-07 1 2.38419e-07H6.70588C7.25817 2.38419e-07 7.70588 0.447716 7.70588 1C7.70588 1.55228 7.25817 2 6.70588 2H2V6.70588C2 7.25817 1.55228 7.70588 1 7.70588C0.447715 7.70588 0 7.25817 0 6.70588V1ZM1 12.2941C1.55229 12.2941 2 12.7418 2 13.2941L2 18H6.70588C7.25817 18 7.70588 18.4477 7.70588 19C7.70588 19.5523 7.25817 20 6.70588 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19L7.7486e-07 13.2941C8.34465e-07 12.7418 0.447716 12.2941 1 12.2941ZM19 12.2941C19.5523 12.2941 20 12.7418 20 13.2941V19C20 19.5523 19.5523 20 19 20H13.2941C12.7418 20 12.2941 19.5523 12.2941 19C12.2941 18.4477 12.7418 18 13.2941 18H18V13.2941C18 12.7418 18.4477 12.2941 19 12.2941Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};

export default withTheme(FullScreenIcon);
