import styled from '@emotion/styled';
import { FC } from 'react';

import { MediaConstants } from '../../styles';

const Inner = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  gap: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  padding-inline: 14px;

  ${MediaConstants.MOBILE} {
    margin: 0 auto;
    width: 60px;
    height: 26px;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex: 1;
  min-width: 60px;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  ${MediaConstants.TABLET_SM} {
    display: block;
    flex: unset;
  }
`;

const Label = styled('div')`
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  font-weight: 500;
  font-size: 14px;

  ${MediaConstants.MOBILE} {
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 60px;
    display: block;
  }
`;

const EmotionsValue = styled('span')`
  color: #39d5c9;
`;

type TProps = {
  label: string;
  value?: string | number;
};

export const AnalyticsItem: FC<TProps> = ({ label, value }) => {
  return (
    <Wrapper>
      <Inner>
        {value !== undefined && <EmotionsValue>{value}%</EmotionsValue>}
      </Inner>
      <Label>{label}</Label>
    </Wrapper>
  );
};
