import styled from '@emotion/styled';
import { ConfigProvider } from 'antd';
import { FC, PropsWithChildren } from 'react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NotificationConfigProvider: FC<PropsWithChildren> = ({ children }) => (
  <ConfigProvider
    theme={{
      token: {
        borderRadius: 10,
      },
    }}
  >
    <Wrapper>{children}</Wrapper>
  </ConfigProvider>
);

export default NotificationConfigProvider;
