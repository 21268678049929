import dayjs from 'dayjs';
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { API } from '../api';
import { TChunk, TVideoChunkData } from '../api/response-types';
import {
  useIncreaseWatchTillEndCountMutation,
  usePatchSessionStatsMutation,
} from '../api/view-api';
import { TStatsResponse } from '../api/view-api/types';
import { useAppDispatch } from '../state';
import { useAuthSelector } from '../state/features/auth/selectors';
import { sendMetricsAction } from '../state/features/socket';
import { saveVideoData } from '../storage';
import { IExportIndex } from '../types/index-core-types';
import { useAuthContext } from './auth-context';
import { useIndexesContext } from './indexes-context';

export type TStatStatus =
  | 'initial'
  | 'recording'
  | 'paused'
  | 'ended'
  | 'final';

type TBaseStats = {
  min: number;
  max: number;
  average: number;
};

type TStatByChunk = Record<keyof Omit<IExportIndex, 'presence'>, TBaseStats>;

const baseStatState: TStatByChunk = {
  amazement: {
    min: 101,
    max: -1,
    average: -1,
  },
  attention: {
    min: 101,
    max: -1,
    average: -1,
  },
  happiness: {
    min: 101,
    max: -1,
    average: -1,
  },
  involvement: {
    min: 101,
    max: -1,
    average: -1,
  },
  tiredness: {
    min: 101,
    max: -1,
    average: -1,
  },
};

type TContext = {
  setStatStatus: (status: TStatStatus) => void;
  onIntervalGoing: (data: HTMLVideoElement) => void;
  setPauseCount: Dispatch<SetStateAction<number>>;
};

const context = createContext<TContext>({
  setStatStatus: () => {},
  onIntervalGoing: () => {},
  setPauseCount: () => {},
});

export const useStatContext = () => useContext(context) as TContext;

export const StatContext: FC<PropsWithChildren> = memo(({ children }) => {
  const dispatch = useAppDispatch();
  const { linkData, videoData, statsData, sessionId } = useAuthContext();
  const { indexData } = useIndexesContext();
  const { userId } = useAuthSelector();
  const statisticVideoStatusRef = useRef<TStatStatus>('initial');

  const [pauseCount, setPauseCount] = useState(0);

  const pauseClickCountRef = useRef(0);

  const statsDataRef = useRef<TStatsResponse>();

  const chunksRef = useRef<TVideoChunkData | undefined>(linkData?.videoChunks);
  const statRef = useRef<TStatByChunk>({ ...baseStatState });
  const lastChunk = useRef<TChunk>();
  const lastChunkIndex = useRef(0);

  const [increaseTillEndCount] = useIncreaseWatchTillEndCountMutation();

  useEffect(() => {
    pauseClickCountRef.current = pauseCount;
  }, [pauseCount]);

  useEffect(() => {
    if (statsData) {
      statsDataRef.current = statsData;
    }
  }, [statsData]);

  const [patchStatsData] = usePatchSessionStatsMutation();

  const currentChunkValues = useRef<IExportIndex[]>([]);

  const chunksToLoad = useRef<TChunk[]>([]);

  const currentIndexes = useRef<IExportIndex>({ ...indexData });

  const isLastChunkSent = useRef(false);

  const idsRef = useRef<{
    video: string;
    link: string;
    chunk: string;
    user: string;
    session: string;
  }>({
    video: '',
    link: '',
    chunk: '',
    user: '',
    session: '',
  });

  useEffect(() => {
    if (videoData && linkData) {
      idsRef.current = {
        video: videoData.id,
        link: linkData.id,
        chunk: linkData.videoChunks.id,
        user: userId ?? '',
        session: sessionId ?? '',
      };
    }
  }, [videoData, linkData, userId, sessionId]);

  useEffect(() => {
    currentIndexes.current = { ...indexData };
  }, [indexData]);

  useEffect(() => {
    if (linkData?.videoChunks) {
      chunksRef.current = linkData?.videoChunks;
      lastChunk.current = linkData.videoChunks.chunks[0];
    }
  }, [linkData]);

  const setStatStatus = useCallback((status: TStatStatus) => {
    if (statisticVideoStatusRef.current !== 'ended') {
      statisticVideoStatusRef.current = status;
    }
  }, []);

  const resetStats = useCallback(() => {
    statRef.current = { ...baseStatState };
  }, []);

  const writeStatChunkToServer = useCallback(async (chunk: TChunk) => {
    try {
      void API.writeChunkStat({
        json: chunk.dataJson,
        endIndex: chunk.end,
        chunksId: idsRef.current.chunk,
        videoId: idsRef.current.video,
        urlFromUser: idsRef.current.link,
      });

      if (statsDataRef.current) {
        const dateEnd = dayjs().toISOString();

        await patchStatsData({
          id: statsDataRef.current.id,
          data: '',
          viewsCount: 1,
          start: statsDataRef.current.start,
          end: dateEnd,
          viewingDuration: dayjs(dateEnd).diff(
            statsDataRef.current.start,
            'seconds'
          ),
          pauseClickCount: pauseClickCountRef.current,
        }).unwrap();
      }
    } catch (e) {
      console.error('Ошибка при записи статистики');
    }
  }, []);

  const writeChunkStats = useCallback((chunk: TChunk) => {
    const newStatChunk = {
      ...chunk,
      dataJson: {
        ...statRef.current,
      },
    };

    chunksToLoad.current.push({
      ...newStatChunk,
    });

    void writeStatChunkToServer(newStatChunk);
  }, []);

  const writeCurrentStats = useCallback(() => {
    currentChunkValues.current.push(currentIndexes.current);

    dispatch(
      sendMetricsAction({
        ...currentIndexes.current,
      })
    );

    (Object.keys(statRef.current) as (keyof TStatByChunk)[]).map((el) => {
      const averageValue =
        currentChunkValues.current.reduce((acc, value) => {
          return acc + value[el];
        }, 0) / currentChunkValues.current.length;

      statRef.current[el] = {
        min:
          statRef.current[el].min > currentIndexes.current[el]
            ? currentIndexes.current[el]
            : statRef.current[el].min,
        max:
          statRef.current[el].max < currentIndexes.current[el]
            ? currentIndexes.current[el]
            : statRef.current[el].max,
        average: averageValue,
      };
    });
  }, []);

  const onIntervalGoing = useCallback((data: HTMLVideoElement) => {
    if (!!chunksRef.current && !!lastChunk.current) {
      if (statisticVideoStatusRef.current === 'recording') {
        saveVideoData({
          videoTimeEnded: data.currentTime,
        });

        if (data.currentTime >= lastChunk.current.end) {
          writeChunkStats(lastChunk.current);
          resetStats();

          if (lastChunkIndex.current === chunksRef.current?.chunks.length - 1) {
            statisticVideoStatusRef.current = 'ended';

            return; // Закончились чанки для записи
          }

          const newChunkIndex = lastChunkIndex.current + 1;
          lastChunk.current = chunksRef.current?.chunks[newChunkIndex];
          lastChunkIndex.current = newChunkIndex;
          currentChunkValues.current = [];
        }

        writeCurrentStats();
      } else if (statisticVideoStatusRef.current === 'paused') {
      } else if (statisticVideoStatusRef.current === 'ended') {
        saveVideoData({
          videoTimeEnded: 0,
        });

        if (!isLastChunkSent.current) {
          isLastChunkSent.current = true;
          writeCurrentStats();
          writeChunkStats(lastChunk.current);

          if (
            idsRef.current.session &&
            idsRef.current.link &&
            idsRef.current.user &&
            idsRef.current.video
          ) {
            increaseTillEndCount({
              sessionId: idsRef.current.session,
              linkId: idsRef.current.link,
              userId: idsRef.current.user,
              videoId: idsRef.current.video,
            }).unwrap();
          }
        }
      }
    }
  }, []);

  return (
    <context.Provider
      value={{
        setStatStatus,
        onIntervalGoing,
        setPauseCount,
      }}
    >
      {children}
    </context.Provider>
  );
});
