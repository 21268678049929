import { Slider } from 'antd';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { useAuthContext } from '../../context/auth-context';
import { useVideoContext } from '../../context/video-context';
import { useAppDispatch } from '../../state';
import { logoutAction } from '../../state/features/auth/auth.slice';
import { boolToText } from '../../utils/primitive';
import { VolumeIcon } from '../icons';
import BackToProfileIcon from '../icons/back-to-profile-icon';
import { ButtonsPanel } from './buttons-panel';
import {
  BackToProfileButton,
  ControlButtonsWrapper,
  ControlsWrapper,
  PlayButton,
  VideoPauseIcon,
  VideoPlayIcon,
  VolumeWrapper,
} from './controls.styles';
import { Duration } from './duration';

export const Controls: FC = () => {
  const dispatch = useAppDispatch();
  const { controlsVisible, togglePlay, play, volume, setVolume } =
    useVideoContext();
  const { linkData } = useAuthContext();

  const sliderWrapperRef = useRef<HTMLDivElement | null>(null);
  const closeTimeoutRef = useRef<NodeJS.Timeout>();

  const [sliderVisible, setSliderVisible] = useState(false);

  const minVolumeLimit = useMemo(() => {
    return linkData?.videoSetting.volumeLimit
      ? linkData.videoSetting.volumeLimitValue ?? 0
      : 0;
  }, [linkData]);

  const handleMouseEnter = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }

    setSliderVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }

    closeTimeoutRef.current = setTimeout(() => {
      setSliderVisible(false);
    }, 800);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logoutAction());
    window.location.reload();
  }, []);

  return (
    <ControlsWrapper visible={boolToText(controlsVisible || !play)}>
      <div className="vd_cntrls_inner">
        <div className="vd_cntrls_inner-side">
          <PlayButton onClick={togglePlay}>
            {play ? <VideoPauseIcon /> : <VideoPlayIcon className="icon" />}
          </PlayButton>
          <Duration />
        </div>
        <ControlButtonsWrapper>
          <VolumeWrapper
            // @ts-ignore
            ref={sliderWrapperRef.current}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className="slider_block"
              style={{
                visibility: sliderVisible ? 'visible' : 'hidden',
                opacity: sliderVisible ? 1 : 0,
              }}
            >
              <Slider
                className="slider"
                vertical
                value={volume}
                onChange={setVolume}
                min={minVolumeLimit}
                max={100}
                style={{
                  pointerEvents: sliderVisible ? 'unset' : 'none',
                }}
              />
            </div>
            <VolumeIcon className="volume" />
          </VolumeWrapper>
          <ButtonsPanel />
          <BackToProfileButton onClick={handleLogout}>
            <BackToProfileIcon className="profile" />
          </BackToProfileButton>
        </ControlButtonsWrapper>
      </div>
    </ControlsWrapper>
  );
};
