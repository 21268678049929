import styled from '@emotion/styled';

export const ModalTitle = styled('div')`
      color: ${({ theme }) => theme.COLORS.WHITE._100};
      font-size: 20px;
      line-height: 24px;
      text-align: left;
      margin-bottom: 30px;
  `



