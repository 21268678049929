import { App } from 'antd';
import { FC } from 'react';

import { Player } from './components/player';
import { AuthContext } from './context/auth-context';
import { IndexesContext } from './context/indexes-context';
import { StatContext } from './context/stat-context';
import { Services } from './services';

export const PlayerWidget: FC = () => {
  return (
    <App>
      <Services>
        <AuthContext>
          <IndexesContext>
            <StatContext>
              <Player />
            </StatContext>
          </IndexesContext>
        </AuthContext>
      </Services>
    </App>
  );
};
