import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { authSelector } from '../state/features/auth/auth.slice';

const BASE_API_PATH =
  process.env.API_HOST ?? 'https://playai-backend.aoneiro.com/';

export const baseEmptyApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_PATH,
    prepareHeaders: (headers, { getState }) => {
      const { authorizationToken } = authSelector(getState() as never);
      if (authorizationToken) {
        headers.set('Authorization', `Bearer ${authorizationToken}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
