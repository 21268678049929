import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authReducer as auth } from './auth.slice';

const authPersistConfig = {
  key: 'auth',
  storage,
};

export const authReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
});
