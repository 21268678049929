import { TServerError } from '../types/server';

export function isServerError(
  error: unknown | TServerError
): error is TServerError {
  return (
    typeof (error as TServerError).status !== undefined &&
    (error as TServerError).data !== undefined &&
    (error as TServerError).data.error !== undefined &&
    (error as TServerError).data.message !== undefined &&
    (error as TServerError).data.statusCode !== undefined
  );
}
