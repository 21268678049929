import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';

import { baseEmptyApi } from '../api';
import { authReducer } from './features/auth';
import { initWebSocket, socketReducer } from './features/socket';
import { viewVideoReducer } from './features/video';
import authMiddleware from './middleware/auth.middleware';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    view: viewVideoReducer,
    socket: socketReducer,
    [baseEmptyApi.reducerPath]: baseEmptyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([authMiddleware, baseEmptyApi.middleware]),
});

store.dispatch(initWebSocket());

export const persistedStore = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
