import { Button, Space, Typography } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import { useCallback, useId } from 'react';

import { DarkTheme } from '../../styles';
import { openGuestNotification } from './guest-norification';
import { useOpenLoginNotification } from './login-notification';
import NotificationConfigProvider from './notification-config-provider';
import { useOpenRegisterNotification } from './register-notification';
import { TBaseNotificationProps } from './types';

type TProps = TBaseNotificationProps & {
  messageApi: MessageInstance;
};

export const useOpenMainAuthNotification = ({
  t,
  notificationApi,
  messageApi,
  parentNoticeKey,
}: TProps) => {
  const notificationKey = useId();

  const handleStayAsGuest = openGuestNotification({
    t,
    notificationApi,
    parentNoticeKey: notificationKey,
  });

  const handleOpenForm = useOpenLoginNotification({
    t,
    notificationApi,
    messageApi,
    parentNoticeKey: notificationKey,
  });

  const handleOpenRegisterForm = useOpenRegisterNotification({
    t,
    messageApi,
    notificationApi,
    parentNoticeKey: notificationKey,
  });

  return useCallback(() => {
    notificationApi.destroy(parentNoticeKey);
    setTimeout(() => {
      const openNotice = () =>
        notificationApi.open({
          message: t('auth.title'),
          key: notificationKey,
          description: (
            <NotificationConfigProvider>
              <Typography.Text
                style={{
                  color: DarkTheme.COLORS.WHITE._100,
                }}
              >
                {t('button.choose')}
              </Typography.Text>
              <Space
                style={{
                  marginTop: 18,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    handleOpenForm(openNotice);
                  }}
                  size="large"
                >
                  {t('button.enter')}
                </Button>
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    handleStayAsGuest(openNotice);
                  }}
                  size="large"
                  style={{
                    color: DarkTheme.COLORS.WHITE._100,
                  }}
                >
                  {t('button.guest')}
                </Button>
              </Space>
              <Button
                type="link"
                style={{
                  padding: 0,
                  marginTop: 20,
                  color: DarkTheme.COLORS.ACCENT._100,
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  handleOpenRegisterForm(openNotice);
                }}
              >
                {t('button.register')}
              </Button>
            </NotificationConfigProvider>
          ),
          placement: 'topRight',
          duration: 0,
          closeIcon: false,
        });

      openNotice();
    }, 100);
  }, [t, parentNoticeKey, handleStayAsGuest]);
};
