import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';

import { useAuthContext } from '../../context/auth-context';
import { useVideoContext } from '../../context/video-context';
import { timeToString } from '../../utils/common';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  gap: 4px;
`;

export const Duration: FC = () => {
  const { videoRef } = useVideoContext();
  const { videoData } = useAuthContext();
  const [, forceRerender] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      forceRerender((prev) => !prev);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Wrapper>
      <span>
        {timeToString(Math.round(videoRef.current?.currentTime || 0))}
      </span>
      <span>/</span>
      <span>{timeToString(Math.round(videoData?.duration || 0))}</span>
    </Wrapper>
  );
};
