import { createRoot } from 'react-dom/client';

import { PlayerWidget } from './PlayerWidget';
import './static/index-core/bundle';
import { initState } from './storage';

export const initPlayer = (container?: HTMLElement) => {
  const wrapper = document.createElement('plai-player');

  initState();

  if (container) {
    container.appendChild(wrapper);
  } else {
    document.body.appendChild(wrapper);
  }

  const playerRoot = createRoot(wrapper);
  playerRoot.render(<PlayerWidget />);
};

if (window !== undefined) {
  initPlayer();
}
