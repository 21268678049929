import {
  Dispatch,
  FC,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useRef,
  useState,
} from 'react';
import Webcam from 'react-webcam';

import { IExportIndex } from '../types/index-core-types';

export type TIndexesContext = {
  SHOW_CAMERA: boolean;
  personVideoRef: RefObject<Webcam>;
  personCameraStarted: boolean;
  setPersonCameraStarted: Dispatch<SetStateAction<boolean>>;
  stoppedByIndexes: boolean;
  setStoppedByIndexes: Dispatch<SetStateAction<boolean>>;
  firstIndexAdded: boolean;
  setFirstIndexAdded: Dispatch<SetStateAction<boolean>>;
  viewGuarantee: boolean;
  setViewGuarantee: Dispatch<SetStateAction<boolean>>;
  analytics: boolean;
  setAnalytics: Dispatch<SetStateAction<boolean>>;
  videoWasStarted: boolean;
  setVideoWasStarted: Dispatch<SetStateAction<boolean>>;
  indexData: IExportIndex;
  setIndexData: Dispatch<SetStateAction<IExportIndex>>;
};

const context = createContext<TIndexesContext>({
  personVideoRef: { current: null },
  SHOW_CAMERA: true,
  personCameraStarted: false,
  setPersonCameraStarted: () => {},
  stoppedByIndexes: false,
  firstIndexAdded: false,
  setFirstIndexAdded: () => {},
  setStoppedByIndexes: () => {},
  viewGuarantee: true,
  setViewGuarantee: () => {},
  analytics: true,
  setAnalytics: () => {},
  videoWasStarted: false,
  setVideoWasStarted: () => {},
  indexData: {
    amazement: 0,
    attention: 0,
    happiness: 0,
    involvement: 0,
    tiredness: 0,
  },
  setIndexData: () => {},
});

export const useIndexesContext = () => useContext(context);

export const IndexesContext: FC<PropsWithChildren> = memo(({ children }) => {
  const SHOW_CAMERA = process.env.NODE_ENV === 'development';
  const personVideoRef = useRef<Webcam>(null);

  const [personCameraStarted, setPersonCameraStarted] = useState(false);

  const [stoppedByIndexes, setStoppedByIndexes] = useState(false);

  const [firstIndexAdded, setFirstIndexAdded] = useState(false);

  const [viewGuarantee, setViewGuarantee] = useState(true);
  const [analytics, setAnalytics] = useState(true);
  const [videoWasStarted, setVideoWasStarted] = useState(false);

  const [indexData, setIndexData] = useState<IExportIndex>({
    amazement: 0,
    attention: 0,
    happiness: 0,
    involvement: 0,
    tiredness: 0,
  });

  return (
    <context.Provider
      value={{
        personVideoRef,
        SHOW_CAMERA,
        personCameraStarted,
        setPersonCameraStarted,
        stoppedByIndexes,
        setStoppedByIndexes,
        firstIndexAdded,
        setFirstIndexAdded,
        viewGuarantee,
        setViewGuarantee,
        analytics,
        setAnalytics,
        videoWasStarted,
        setVideoWasStarted,
        indexData,
        setIndexData,
      }}
    >
      {children}
    </context.Provider>
  );
});
