import styled from '@emotion/styled';
import { forwardRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';

import { useIndexesContext } from '../context/indexes-context';
import { useVideoContext } from '../context/video-context';

type TRef = Webcam | null;

const Wrapper = styled('div')`
  width: 0;
  height: 0;
  position: relative;
`;

const HideBlock = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
`;

export const UserVideo = forwardRef<TRef>((props, ref) => {
  const { setPersonCameraStarted, SHOW_CAMERA, personCameraStarted } =
    useIndexesContext();
  const { isCameraError } = useVideoContext();

  const onPause = useCallback(() => {
    setPersonCameraStarted(false);
  }, []);

  const onPlay = useCallback(() => {
    if (!isCameraError) {
      setPersonCameraStarted(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (
        !personCameraStarted &&
        ref &&
        'current' in ref &&
        ref.current &&
        ref.current.video?.played &&
        !isCameraError
      ) {
        setPersonCameraStarted(true);
      }

      if (isCameraError) {
        setPersonCameraStarted(false);
      }
    }, 1000);
  }, [personCameraStarted, isCameraError]);

  return (
    <Wrapper>
      <Webcam
        autoPlay
        audio={false}
        mirrored={true}
        ref={ref}
        controls={false}
        screenshotFormat="image/jpeg"
        forceScreenshotSourceSize={true}
        onPause={onPause}
        onPlay={onPlay}
        playsInline
        loop
        style={{
          width: 200,
          height: 200,
          position: 'absolute',
          visibility: SHOW_CAMERA ? 'unset' : 'hidden',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      />
      <HideBlock />
    </Wrapper>
  );
});
