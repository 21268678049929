import { ThemeProvider } from '@emotion/react';
import { ConfigProvider } from 'antd';
import { FC, PropsWithChildren, useLayoutEffect } from 'react';

import { DarkTheme } from '../styles';
import '../styles/style.scss';
import { objToRootStyleProps } from '../utils/style';

export const ThemeService: FC<PropsWithChildren> = ({ children }) => {
  useLayoutEffect(() => {
    const root = document.documentElement;

    objToRootStyleProps(root, DarkTheme, '--theme');
  }, []);

  return (
    <ThemeProvider theme={DarkTheme}>
      <ConfigProvider
        locale={{
          locale: 'ru',
        }}
        theme={{
          token: {
            colorPrimary: DarkTheme.COLORS.ACCENT._200,
            colorError: DarkTheme.COLORS.DANGER,
            colorTextBase: DarkTheme.COLORS.WHITE._100,
            colorBgBase: DarkTheme.COLORS.BLACK._200,
            borderRadius: 4,
            zIndexPopupBase: 4,
          },
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeProvider>
  );
};
