import axios, { AxiosResponse } from 'axios';

import { ACCESS_TOKEN_LS_PATH } from '../storage';
import { baseEmptyApi } from './api';
import { TCreatedVideo, TLinkResponse } from './response-types';
import viewApi from './view-api';

export const BASE_API_PATH =
  process.env.API_HOST ?? 'https://playai-backend.aoneiro.com/';

const baseAPIInstance = axios.create({
  baseURL: BASE_API_PATH,
});

baseAPIInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(ACCESS_TOKEN_LS_PATH);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const API = {
  getVideo: (id: string) => {
    return baseAPIInstance.get(`video/${id}`) as Promise<
      AxiosResponse<TCreatedVideo>
    >;
  },
  getLink: (id: string) => {
    return baseAPIInstance.get(`link/${id}`) as Promise<
      AxiosResponse<TLinkResponse>
    >;
  },
  getVideoStream: (videoId: string) => {
    return baseAPIInstance.get(`streams/${videoId}`) as Promise<
      AxiosResponse<BufferSource>
    >;
  },
  writeChunkStat: (data: {
    json: any;
    urlFromUser: string;
    videoId: string;
    endIndex: number;
    chunksId: string;
  }) => {
    return baseAPIInstance.post('raw-statistics', data);
  },
  login: (email: string, password: string) => {
    return baseAPIInstance.post('auth/login', {
      email,
      password,
    }) as Promise<
      AxiosResponse<{
        authorizationToken: string;
      }>
    >;
  },
};

export { baseEmptyApi, viewApi };
